@import './variables.scss';
.dialog-btn{
    .MuiButton-label{
        font-weight: bold;
    }
}

.manual-dialog{
    .MuiDialog-paperScrollPaper{
        width: 100%;
      }
}

.cancel-btn{
    background-color: tomato !important;
}
.cropper{
    background-color: ghostwhite;
    text-align: center;
}

/* sidebar  */
.sidebar.nav__link{
    color: $txt-color;
    text-decoration: none;
}
.sidebar.nav__link:hover{
    color: $txt-color;
    text-decoration: none;
}
.side_nav_item{
    border-bottom: 1px solid whitesmoke !important;
}

.side_nav_item.active{
   
    color:black !important;
  
    .nav__icon{
        color: #fff !important;
    }
    .nav_item_text>span{
        font-weight: bold;
    }

}


.MuiButtonBase-root.MuiButton-root.MuiButton-text.Mui-disabled{
    opacity: 0.5;
}

//loader
.loader {
    position: fixed;
    z-index: 999999;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black6;
    overflow: hidden;
    height: 100%;
  }
  
.tab-selected{
    position: absolute;
    right: 2px;
    color: #03a9f4;
    font-size: 16px !important;
    bottom: 2px;
}
.apexcharts-toolbar{
    z-index: 0 !important;
}

.select-dark{
    height: 38px;
    .custom__select__control{
        background: #364e71;
        border-color: #364e71;
        .custom__select__single-value{
            color: white;
        }
    }
    .custom__select__menu-list{
        background-color: #191845;
    
    }
    .custom__select__option--is-focused {
        background-color: #223351;
    }
}
.select-light{
    height: 38px;
}

.buttonwrapper {
    list-style: none;
    padding: 5px;
    display: inline-flex;
    width: auto;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.13);
}

.buttonwrapper li {
    display: block;
    float: left;
    margin-left: 35px;
    height: 35px;
    min-width: 80px;
}

.buttonwrapper li:first-child {
    margin-left: 0;
}

.buttonwrapper label {
    display: block;
    color: #FFFFFF;
    font-family: Nunito Sans Light;
    opacity: 1;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    padding: 8px 10px;
    margin-bottom: 0;
    border-radius: 50%;
    cursor: pointer;
}

.buttonwrapper li.active label {
    background-color: #182142;
    border-radius: 50px;
}

.filter_light{
    border: 1px solid black;
    .buttonwrapper{
        background-color: rgba(255, 255, 255, 0.13) !important;
    }
    li.active label {
        background-color: #182142 !important;
        color: white !important;
    }
    li label{
        color: #000 !important;
    }
}

