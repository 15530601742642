.confirmation_dialogbox__content{
    background-color: white;
    white-space: nowrap;
    display: flex;
    width: 500px;
    flex-direction: column;
    text-align: center;
    border-radius: 10px;
}

.dialogbox_close_btn{
    position: absolute;
    right: 0px;
    top: -10px;
    cursor: pointer;
}
.dialogbox_header__icon{
    color: #FFCA28;
    background-color: #FFF3CD;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.dialogbox_closebtn__img{
    color: black;
    width: 10px;

}
.dialogbox_body__content{
    padding: 20px 10px;
}
.dialogbox_body__content h4{
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
}
.dialogbox_body__content p{
    font-size: 10px;
    color: #969CA4;
}
.dialogbox_footer__content{
    padding: 15px;
    border-top: 1px solid #E2E2E8;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.dialogbox_footer__content button{
    font-size: 14px;
    letter-spacing: 0.5px;
    padding: 3px 15px;
    border-radius: 5px;
    margin-left: 8px;
}
.dialogbox_delete__btn{
    border: 1px solid #E2E2E8;
    background: none;
}
.dialogbox_cancel__btn{
    background-color: #282828;
    border: none;
    color: #FFFFFF;
    
    font-weight: 100;
}