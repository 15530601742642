.customer_center_overview_main_container{
    width: 96%;
    min-height: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 21px;
}
.customer_center_overview_dateBox input{
    width: 175px;
    height: 30px;
    background: #F5F8FA;   
     border: none;
    border-radius: 7px;
    outline: none;
    font-size: 13px;

}
.customer_center_overview_head{
    width: 96%;
    height: 13%;
    min-height: 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    background-color: rgb(255, 255, 255);
    padding-top: 20px;

}
.customer_center_overview_exportBtn{
    width: 100%;
    position: relative;
}
.customer_center_overview_exportBtn button{
    position: absolute;
    right: 0%;
}
.customer_center_account_table {
  
    padding: 0;
}





/* ------Consumer Account section---- */

.main_consumer_account_div{
    width: 98%;
}
.consumer_account_table__head th,
.consumer_account_table__head td{
    padding: 0.75rem 1.75rem 0.75rem 0;
}
.consumer_account_input_section{
    background-color: #FFFFFF;
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.consumer_account_form_section1{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px
}
.consumer_account_input_label{
    margin-right: 25px;
}

.consumer_account_input_name input{
    max-width: 200px;
}
.consumer_account_input_label input{
    border: 1px solid #E2E2E8;
    border-radius: 5px;
    margin-top: 3px;
    padding: 7px 12px;
    width: 230px;
    color: #969CA4;
    outline: none;
}
.consumer_account_form_section2{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 10px;
}
.consumer_account_reset_btn{
    border: 1px solid #E2E2E8;
    border-radius: 5px;
    background: none;
    font-size: 14px;
    letter-spacing: 0.5px;
    padding: 7px 25px;
    font-weight: 500;
}
.consumer_account_search_btn{
    font-size: 14px;
    letter-spacing: 0.5px;
    padding: 8px 20px;
    background-color: #282828;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 8px;
}




/* ----profermance */

.consumer_account_main_container{
    width: 95%;
    min-height: 500px;
    border-radius: 10px;
    margin: 10px auto ;
    display: flex ;
    flex-direction: column;
    gap: 10px; 
}

.consumer_account_account_listing{
    width: 100%;
    min-height: 300px;
    border-radius: 10px ;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    padding-bottom: 20px;
    border-radius: 10px;
}







/* ------------------------------ */


    .consumer_account_page_mainContainer_head{
        width: 100%;
        height: 13%;
        min-height: 10px;
        border-radius: 10px 10px 0 0 ;
        background-color: rgb(255, 255, 255);
    }
    .consumer_account_page_mainContainer_head>p{
        margin-left: 24px;
        padding-top: 24px;
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #282828;

    }
 .consumer_account_page_mainContainer_body{
     width: 95%;
     min-height: 300px; 
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     border-radius: 10px;     
     background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box; 

 }
 .consumer_account_page_mainContainer_body_allTabs{
     display: flex;
     width: 100%;
     height: 50px;
     align-items: center;
     padding-left: 17px;
     background: #F5F8FA;
    border-bottom: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #969CA4;
    
 }
 .consumer_account_page_TabsBtn{
    padding: 15px 20px 0 20px;
     height: 100%;
     display:inline-block;
     -webkit-appearance:button;
     position:relative;
     cursor: pointer;
   
 }
 .AllorderPage_activeTab {
    background-color: inherit;
    border-bottom: solid 2px  #282828;
    color: #282828;
  }

  .consumer_account_page_allContent_top{
    width: 100%;
    height: 10%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* gap: 10px; */
}
.consumer_account_page_allContent_top_totalOrder{
   font-size: 14px;
   font-weight: 600;
}
#consumer_account_page_allContent_top_searchBtn{
    display: flex;
    width: 165px;
    height: 30px;
    justify-content: space-between;
}



#consumer_account_page_allContent_top_searchBtn input{
    border: none;
    outline: 0;
    width: 140px;
   
    border-radius: 0 6px 6px 0;
    background-color: rgb(185, 176, 176);
    background: #F5F8FA;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
}


 .consumer_account_page_mainContainer_body_allContent{
     width: 96%;
     padding-top: 20px;
     
     background-color: rgb(255, 255, 255);
 }
 .consumer_account_page_mainContainer_body_bottom{
     display: flex;
     align-items: center;
     justify-content: space-between;
     width: 96%;
     min-height: 68px;
     border-radius: 0 0 10px 10px;
     background-color: rgb(255, 255, 255);
     
 }
 .consumer_account_page_mainContainer_body_bottom_selectQty{
     width: 75px;
     height: 35px;
     background: #F5F8FA;
     border-radius: 6px;
     
 }
 .consumer_account_page_mainContainer_body_bottom_selectQty select{
     border: none;
     outline: 0;
     width: 65px;
     height: 35px;
     padding: 0 12px;
     background: #F5F8FA;
     border-radius: 6px;
     font-family: Helvetica Neue;
     font-style: normal;
     font-weight: 500;
     font-size: 14px;
     line-height: 17px;
     letter-spacing: 0.5px;     
     color: #282828;
 }

 .consumer_account_page_mainContainer_body_bottom_pagenation a {
    color: black;
    float: left;
    padding: 5px 12px;
    text-decoration: none;
    transition: background-color .3s;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #282828;
  }
.consumer_account_page_mainContainer_body_bottom_pagenation .consumer_account_page_mainContainer_body_bottom_pagenation>a.active {
  background-color: dodgerblue;
  color: white;
}
.consumer_account_page_mainContainer_body_bottom_pagenation a:hover:not(.active) {
  background: #282828;
  border-radius: 4px;
  color: white;}






/* ------------------------- */



/* ------Ticket Listing------------ */

.consumer_account_listing{
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.consumer_account_listing_box{
    padding: 5px;
    margin: 25px;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
}
.consumer_account_btn__section{
    position: relative;
}
.add_consumer_account_export_btn{
    position: absolute;
    right: 150px;
    top: 10px;
    letter-spacing: 0.5px;
    padding: 7px 20px;
    background-color: #282828;
    color: white;
    border: none;
    border-radius: 4px;
    z-index: 999;
}
.add_consumer_account_btn{
    position: absolute;
    right: 5px;
    top: 10px;
    letter-spacing: 0.5px;
    padding: 7px 10px;
    background-color: #282828;
    color: white;
    border: none;
    border-radius: 4px;
}

.consumer_account_detail__table{
    position: relative;
}
.consumer_account_listing_detail__table{
    overflow: auto;
    margin-top: 30px;
    white-space: nowrap;
}
.consumer_account_listing_detail__table  tr th{
    color: #969CA4;
    border-top: none;
}
.consumer_account_listing_detail__table tr td{
    border: none;
    border-top: 1px solid #E2E2E8;
}
.consumer_account_listing_detail__table
td.consumer_account_listing_action_body,
th.consumer_account_listing_action_header{
    position: sticky;
    right: 0px;
    padding-left: 10px;
    background-color: #FFFFFF ;
}




.consumer_account_listing_actiontable__icon1{
    background-color: #F5F8FA;
    padding: 4px;
    border-radius: 6px;
    width: 25px;
}
.consumer_account_actiontable__icon2{
    margin: 0px 4px;
    background-color: #F5F8FA;
    padding: 5px 6px;
    border-radius: 6px;
    width: 26px;
}
.consumer_account_listing_detail__table td,
.consumer_account_listing_detail__table th{
    padding: 0.75rem 1.75rem 0.75rem 0 !important;
}
 
.actiondataleft{
    display: flex;
    justify-content: center;
    gap: 4px;
    

    
}
.consumer_account_listing_detail__table{
    font-size: 14px;
    
}
.actiondataleft,
.actionleft{
    padding-left: 10px;
    border-left: 1px;
}

/* ---- */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.Profile_img{
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
}
.Profile_Name{
    margin-left: 1rem;
}
.Profile_change_Btn{
    box-sizing: border-box;
}
.Shipping_Addres{
    width: 400px;
    min-height: 10px;
    word-wrap: break-word;
    text-align: left;
}
.Default_bank{
    color: #F68D7D;
}
.Edit_Delete_btn{
    color: #14B4E6;
    font-size: .9rem;
}
.Default_address{
    color: #F68D7D; 
}


/*  */
/*  */
.Dashed_Add_Profile{
    height: 1.5rem;
    width: 1.5rem;
    margin-top: 1.2rem;
    margin-left: 1.2rem;
}
.owijfoi{
    height: 4rem;
    width: 4rem;
    border: dashed 1px ;
    border-radius: 50%;
}



/* ------- */
.consumer_view_account_footer{
    background-color: rgb(255, 255, 255);
    height: 80px;
    margin-top: 5px;
    position: relative;
}
.consumer_view_account_footer_contant{
    position: absolute;
    right: 0;
    top: 20px;
}