
.table-items{
    padding:1.6%  !important;
}
.edit-action{
    padding: 17% 0 !important
}
.Dotted_image{
    width:75px;
    height: 80px;
    margin: 5px !important;
    border-radius: 6px;
    align-items: center;
    display:flex;
    justify-content:center;
    border: 3px dotted black
   
}
.Dotted_image input{
    visibility: hidden;
    width:0%;
    height: 0%;
}

.font-size-account{
    font-size: 15px;
}


.Select_bank_inp select{
    width: 100%;
    height: 40px;
    border-radius: 5px;
}
.addAuthPersonBtn button{
    background-color: black;
    color: white;
    border-radius: 9px;
    align-items: center;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    height: 35px;
    margin-top: 4px;
}

.MasterAuthorzedPerson{
    border: none;
    background-color: white;
    color: #F68D7D;
    margin-bottom: 5px;
}

.Edit_button_EmpName{
    border: none;
    background-color: white;
    color: #14B4E6;
    font-weight: 500;
}

.Line_Enpname{
    border: none;
    background-color: white;
    color: #14B4E6;
    font-weight: 500;
}

.AddSubAccount_EnpName{
    border: none;
    background-color: white;
    color: #14B4E6;
    font-weight: 500;
}
.Delete_btn_NRIC{
    border: none;
    background-color: white;
    color: #14B4E6;
    font-weight: 500;
    font-size: 15px; 
}

.TypeOfaBusiness label{
    font-size: 15px;
}
.MerchantID span{
   font-size: 15px; 
}

.DetailsOfmerchant strong{
    font-size: 16px;
}
.Merchant_type span label{
    font-size: 15px;
}
.All_comapny_inputs span {
    font-size: 15px;
}
.Image_dotted label{
    font-size: 15px;
}
.All_red_stars {
    color: red;
}
.Director_partners label {
    font-size: 15px; 
}
.btn_edit_delete button {
    font-size: 15px;
    color: #14B4E6;
}
/* .merchant-account-btn{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  

} */
/* .ss{
    min-height:200px
} */

.main_container_merchantAccount{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    /* align-items:space-between; */
    position: relative;
}
.boxxx{
    min-height: 600px;
}

.bank-account-details{
    position: relative;
    display: flex;
    flex-direction: row;
    padding-left: 10%;
}
.bank-account-details-section2{
    display: flex;
    flex-direction: column;
    padding-right: 20px;
}
.bank-account-details-edit{
    position: absolute;
    right: 30px;
}

p.account-details {
    margin-bottom: 1px;
    width: 150px;
}