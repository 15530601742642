body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  text-decoration: none !important;
}

.pointer{
  cursor: pointer;
}
.bold{
  font-weight: bold;
}

.fntSz10{font-size: 10px !important;}
.fntSz11{font-size: 11px !important;}
.fntSz12{font-size: 12px !important;}
.fntSz13{font-size: 13px !important;}
.fntSz14{font-size: 14px !important;}
.fntSz15{font-size: 15px !important;}
.fntSz16{font-size: 16px !important;}
.fntSz17{font-size: 17px !important;}
.fntSz18{font-size: 18px !important;}
.fntSz19{font-size: 19px !important;}
.fntSz20{font-size: 20px !important;}
.fntSz21{font-size: 21px !important;}
.fntSz22{font-size: 22px !important;}
.fntSz23{font-size: 23px !important;}
.fntSz24{font-size: 24px !important;}
.fntSz25{font-size: 25px !important;}
.fntSz26{font-size: 26px !important;}
.fntSz27{font-size: 27px !important;}
.fntSz28{font-size: 28px !important;}
.fntSz29{font-size: 29px !important;}
.fntSz30{font-size: 30px !important;}

.vh-10{height: 10vh;}
.vh-20{height: 20vh;}
.vh-30{height: 30vh;}
.vh-40{height: 40vh;}
.vh-50{height: 50vh;}
.vh-60{height: 60vh;}
.vh-70{height: 70vh;}
.vh-80{height: 80vh;}
.vh-90{height: 90vh;}
.vh-100{height: 100vh;}

.vw-10{width: 10vw;}
.vw-20{width: 20vw;}
.vw-30{width: 30vw;}
.vw-40{width: 40vw;}
.vw-50{width: 50vw;}
.vw-60{width: 60vw;}
.vw-70{width: 70vw;}
.vw-80{width: 80vw;}
.vw-90{width: 90vw;}
.vw-100{width: 100vw;}

.h-10{height: 10%;}
.h-20{height: 20%;}
.h-30{height: 30%;}
.h-40{height: 40%;}
.h-50{height: 50%;}
.h-60{height: 60%;}
.h-70{height: 70%;}
.h-80{height: 80%;}
.h-90{height: 90%;}
.h-100{height: 100%;}

.w-10{width: 10%;}
.w-20{width: 20%;}
.w-30{width: 30%;}
.w-40{width: 40%;}
.w-50{width: 50%;}
.w-60{width: 60%;}
.w-70{width: 70%;}
.w-80{width: 80%;}
.w-90{width: 90%;}
.w-100{width: 100%;}



.h-10 {
  height: 10%;
}
.h-20 {
  height: 20%;
}
.h-30 {
  height: 30%;
}
.h-40 {
  height: 40%;
}
.h-60 {
  height: 60%;
}
.h-70 {
  height: 70%;
}
.h-80 {
  height: 80%;
}
.h-90 {
  height: 90%;
}

.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}

.vh-10 {
  height: 10vh;
}
.vh-20 {
  height: 20vh;
}
.vh-30 {
  height: 30vh;
}
.vh-40 {
  height: 40vh;
}
.vh-50 {
  height: 50vh;
}
.vh-60 {
  height: 60vh;
}
.vh-70 {
  height: 70vh;
}
.vh-80 {
  height: 80vh;
}
.vh-90 {
  height: 90vh;
}
.vh-100 {
  height: 100vh;
}

.vw-10 {
  width: 10vw;
}
.vw-20 {
  width: 20vw;
}
.vw-30 {
  width: 30vw;
}
.vw-40 {
  width: 40vw;
}
.vw-50 {
  width: 50vw;
}
.vw-60 {
  width: 60vw;
}
.vw-70 {
  width: 70vw;
}
.vw-80 {
  width: 80vw;
}
.vw-90 {
  width: 90vw;
}
.vw-100 {
  width: 100vw;
}

.w-fit-content {
  width: fit-content;
}
.h-fit-content {
  height: fit-content;
}
.text_wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.box-shadow {
  box-shadow: 0px 5px 35px #8392a54d;
}

.object-fit-cover {
  object-fit: cover;
}
.object-fit-contain {
  object-fit: contain;
}
.object-fit-fill {
  object-fit: fill;
}

.text-line-through {
  text-decoration: line-through;
}
.cursor-text{
  cursor: text;
}

.b-0{
  bottom: 0;
}
.t-0{
  top: 0;
}
.l-0{
  left: 0;
}
.r-0{
  right: 0;
}
.pointer{
  cursor: pointer;
}
/** Coment this line when debugging*/
iframe{
  display:none !important;
}
/**End Coment this line when debugging*/
.app-wrapper {
  height: calc(100vh - 20px);
  margin: 1rem;
}

.customized-app {
  height: 90%;
}

.customized-channelpreview-item:hover {
  cursor: pointer;
}

.channel-selector {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.channel-selector__icons {
  display: flex;
}

.custom-channel-list {
  height: 64px;
  display: flex;
  justify-content: space-between;
}
