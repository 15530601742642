// .react-table {
//   max-width: 100% !important;
//   background-color: #fff;
//   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
//   -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
//   -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
//   border-radius: 5px;
//   margin-top: 21px;
//   // position: fixed;
//   left: 242px;
//   right: 19px;
//   top: 133px;
//   bottom: 55px;
//   overflow-y: hidden;
// }

.rt-table {
  // border: 1px solid blue;
  // margin-bottom: 70px;
}

.ant-input:focus {
  outline: none !important;
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: none !important;
}

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: none !important;
  border-right-width: 1px !important;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-calendar-today .ant-calendar-date {
  color: #3a3abf !important;
  font-weight: bold;
  border-color: #3a3abf !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-bottom: 1px solid #E2E2E8 !important;
  -moz-box-shadow: none !important;
  border: none;
  // margin-top: -20px;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .rt-th.-sort-desc{
  box-shadow: none!important;
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  color: #fff !important;
  background: linear-gradient(60deg, #3a3abf, #3a3abf)!important;
  box-shadow: #8181ec;
  border: 1px solid transparent;
}
.ant-tag-blue {
  color: #fff;
  background: #3a3abf;
  border-color: #3a3abf;
}

.rt-th {
  border: none !important;
  margin: 7px 0px 7px 0px;
  font-size: 0.8rem;
  padding: 2px 15px !important;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #969CA4;
}
.ReactTable .rt-th, .ReactTable .rt-td{
  white-space: unset !important;
}
.rt-td:last-child, .rt-th:last-child{
  border-right: 0px solid #E2E6FA !important;
}
.rt-td {
  justify-content: center;
  display: flex;
  align-items: center;
  border: none !important;
  padding: 10px 10px !important;
  // margin: 8px 0px;
  font-size: 0.75em;
  font-weight: 400;
  border-bottom: 1px solid #E2E2E8 !important;
  // border-right: 1px solid #E2E2E8 !important;
  text-align: center !important;
}

.ReactTable .-pagination {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  border: none;
  border-top: none !important;
}

.ReactTable {
  border: none !important;
  padding: 0rem 0rem 1rem 0rem;
  // border: 1px solid red !important;
  height: 100% !important;
  
  .rt-tbody{
    max-height: 360px;
    overflow-x: hidden;
  }

  .rt-thead{
    background-color: #FAFBFD;
  }

  input {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 0px !important;
    font-size: 0.75rem !important;
    transition: all 0.5s;
  }
  input:focus {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    border-radius: 0px !important;
    font-size: 0.75rem !important;

    border-bottom: 1px solid #3a3abf;
  }
  .rt-noData {
    max-height: calc(100vh - 15rem);
    font-size: 0.75rem !important;
    font-weight: 600;
  }
  // .rt-th, .ReactTable .rt-td {
}

/* width */
::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #E2E2E8;
  border-radius: 90px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #E2E2E8;
}

.filter-div {
  display: flex;
  justify-content: end;
}
.time-range-div {
  margin-left: auto;
  cursor: pointer;
}

.filter-lable {
  background: linear-gradient(60deg, #3a3abf, #3a3abf)!important;
  box-shadow: #8181ec;
  padding: 10px !important;
  border-radius: 100%;
}
.filter-lable svg {
  fill: #fff;
  height: 1.3rem !important;
}

.react-table {
  max-width: 100% !important;
}

.-pagination {
  justify-content: flex-end !important;
}

.-previous {
  flex: inherit !important;
}

.-center {
  flex: inherit !important;
}
.-next {
  flex: inherit !important;
}

.-pageInfo {
  font-size: 0.8rem !important;
}

.select-wrap select {
  font-size: 0.8rem !important;
}

.-next .-btn {
  font-size: 0.8rem !important;
  color: white !important;
  background: linear-gradient(60deg, #3a3abf, #3a3abf)!important;
  box-shadow: #8181ec;
}

.-previous .-btn {
  font-size: 0.8rem !important;
  color: white !important;
  background: linear-gradient(60deg, #3a3abf, #3a3abf)!important;
  box-shadow: #8181ec;
}

.pagination-bottom {
  margin-top: 20px;
}

.table-lable-div {
  display: flex;
  margin-left: 10px;
  // margin-bottom: 10px;
}

.table-icon svg {
  fill: #fff;
}
.table-icon {
  background: linear-gradient(60deg, #3a3abf, #3a3abf) !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #8181ec;
  padding: 1.2rem 1.15rem;
  margin-top: -16px;
  border-radius: 0.3rem;
  position: fixed;
  top: 150px;
  left: 265px;
}

.table-lable {
  margin-top: 13px;
  margin-left: 15px;
  // font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.03rem;
  white-space: nowrap;
}
.table-filters {
  width: 100%;

  // margin: 5px 20px;
}

.date-filter-lable {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  margin-left: 10px;
  width: 14rem !important;
}

.date-filter-lable input {
  font-size: 0.8rem;
}
