@import "./variables.css";
@import "./analytics.css";

.cover {
  object-fit: cover;
}
.contain {
  object-fit: contain;
}
.fill {
  object-fit: fill;
}

.MuiListItemIcon-root {
  min-width: 30px !important;
  align-items: center !important;
}

.w-49 {
  width: 49%;
}
.fit-content{
  height: fit-content;
}
.sun-editor-editable {
  max-height: calc(100vh - 240px) !important;
  min-height: 50vh !important;
}

.border-dashed{
  border: 1px dashed #dee2e6!important;
}
.fit-content{
  width: fit-content;
}
.error{
  /* position: absolute; */
  color: red;
  font-size: 0.6rem;
}
/* label.error:before,
.gen-error:before {
    content: "\e915";
    font-family: "icomoon" !important;
    position: absolute;
    left: 0;
    top: 0;
} */
.card_bg{
  background: white;
  margin-bottom: 20px;
  padding: 10px ;
}