.slider-border {
  border: 2px solid gray;
  border-radius: 6px;
}

.add_brand_btn {
  background-color: black;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  border: none;
  height: 28px;
}
.Edit_Action_btn {
  border: none;
  border-radius: 3px;
}
.Delete_Action_btn {
  border: none;
  border-radius: 3px;
}

.Main_content_box_brand_SearchBtn {
  background-color: rgb(255, 255, 255);
}

#Brand_searchBtn {
  display: flex;
  width: 175px;
  justify-content: space-between;
}

#Brand_searchBtn button {
  border: none;
  outline: 0;
  padding-top: 4px;
  border-radius: 6px 0 0 6px;
  background: #f5f8fa;
  width: 35px;
}
#Brand_searchBtn input {
  border: none;
  outline: 0;
  width: 180px;

  border-radius: 0 6px 6px 0;
  background-color: rgb(185, 176, 176);
  background: #f5f8fa;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
}
.img_brand_SearchBtn {
  margin-bottom: 3px;
}
/* .btns_Search_Addbrand{
  
} */
