

.merchant_report_page_searchBox{
    width: 1000px;
    min-height: 130px;
    margin-top: 10px;
    padding: 19px 19px;
    font-size: 16px;
    margin-left: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    border-radius: 5px;
}


.merchant_report_page_mainContainer{
    width:1000px;
    min-height: 264px;
    margin-top: 20px;
    margin-left: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
    padding-bottom: 25px; 
    margin-bottom: 25px;
    
}
    .merchant_report_page_mainContainer_head{
        width: 95%;
        height: 13%;
        min-height: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-radius: 10px 10px 0 0 ;
        background-color: rgb(255, 255, 255);
        
    
    }
    .merchant_report_page_mainContainer_head>p{
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        padding-top: 18px;
        line-height: 17px;
        letter-spacing: 0.02em;

    }
    .merchant_report_page_allContent_top{
        display: flex;
        justify-content: space-between;
    }
    .OrdersPage_allContent_top button,
    .merchant_report_page_allContent_top button{
        border: none;
        width: 91px;
        background-color: rgb(15, 12, 12);
        color: white;
        height: 38px;
        border-radius: 5px;
    }
.merchant_report_page_orderTab_maincontant{
    width: 98%;
    margin-top: 20px;
}    
.merchant_report_page_orderTab_Btn{
    padding: 16px;
}
.merchant_report_page_orderTab_maincontant_dateManage{
    width: 220px;
    font-size: 12px;
    font-weight: 600;
}
.marchatPage_ordertab_allContent_body{
    overflow-x: auto;
}
.merchant_report_page_allContent_top_totalReport{
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;   
    letter-spacing: 0.02em;
    text-transform: uppercase;    
    color: #282828;
    margin-top: 11px;
    font-size: 14px;
}
.product-category-border{
    font-size: 14px;
    color: rgb(92, 87, 87)5;
}


 .merchant_report_page_mainContainer_body{
     width: 95%;
     min-height: 300px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     border-radius: 10px;     
     background: #FFFFFF;
     border: 1px solid #E2E2E8;
     box-sizing: border-box; 

 }


 .merchant_report_page_mainContainer_body_allContent{
     width: 96%;
     min-height: 200px;
     padding-top: 25px;
     border-bottom:3px solid grey ;
     background-color: rgb(255, 255, 255);
 }
 .merchant_report_page_allContent_top{
     padding: 1px 0 23px 0 ;
 }
 .merchant_report_page_mainContainer_body_bottom{
     display: flex;
     align-items: center;
     justify-content: space-between;
     width: 96%;
     min-height: 68px;
     border-radius: 0 0 10px 10px;
     background-color: rgb(255, 255, 255);
     
 }
 .merchant_report_page_mainContainer_body_bottom_selectQty{
     width: 75px;
     height: 35px;
     background: #F5F8FA;
     border-radius: 6px;
     
 }
 #inputGroupSelect01{
     margin-left: 16px;
 }

 #inputGroupSelect01,
 .merchant_report_page_mainContainer_body_bottom_selectQty select{
     border: none;
     outline: 0;
     width: 65px;
     height: 35px;
     padding: 0 15px;
     background: #F5F8FA;
     border-radius: 6px;
     font-family: Helvetica Neue;
     font-style: normal;
     font-weight: 500;
     font-size: 14px;
     line-height: 17px;  
     letter-spacing: 0.5px;
     color: #282828;
 }


 .merchant_report_page_mainContainer_body_bottom_pagenation a {
    color: black;
    float: left;
    padding: 5px 12px;
    text-decoration: none;
    transition: background-color .3s;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #282828;
  }
  
  .merchant_report_page_mainContainer_body_bottom_pagenation .merchant_report_page_mainContainer_body_bottom_pagenation>a.active {
    background-color: dodgerblue;
    color: white;
  }
  
  .merchant_report_page_mainContainer_body_bottom_pagenation a:hover:not(.active) {
      background: #282828;
  border-radius: 4px;
  color: white;}


  .merchant_report_page_mainContainer_body_allContent_table{
      width: 100%;
      
  }
  .merchant_report_page_mainContainer_body_allContent_table th{
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;    
    letter-spacing: 0.5px;
    color: #969CA4;
  }

  .merchant_report_page_mainContainer_body_allContent_table tr{
      border-bottom: 1px solid rgb(194, 190, 190);
  }
  .merchant_report_page_mainContainer_body_allContent_table  td{
      padding: 27px 0;
  }
  .merchant_report_page_mainContainer_body_allContent_table  th{
    padding: 10px 0;
  }
  .merchant_report_page_mainContainer_body_allContent_table td{
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;    
    letter-spacing: 0.5px;
    
    color: #282828;
  }
  .merchant_report_page_mainContainer_body_allContent_table_productName{
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    letter-spacing: 0.5px;
    font-weight: 600;
    color: #000000;
  }
  .merchant_report_page_mainContainer_body_allContent_table_action{
      /* border-left: 2px solid rgb(182, 169, 169); */
      text-align: center;
      display: flex;
      /* justify-content: center; */
      align-items: center;
  }
  .merchant_report_page_mainContainer_body_allContent_table_action button{
    background: #F5F8FA;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: none;
    outline: none; 
  }

 
/* ---------------------------- */
.merchant_action_mainContainer_body{
    width: 95%;
    min-height: 250px; 
    display: flex;
    margin: 10px auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;     
    background: #FFFFFF;
   border: 1px solid #E2E2E8;
   box-sizing: border-box; 

}
.merchant_action_mainContainer_body_allTabs{
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    padding-left: 17px;
    display: flex;
    /* background: #F5F8FA; */
   border-bottom: 1px solid #E2E2E8;
   box-sizing: border-box;
   border-radius: 10px 10px 0px 0px;
   font-family: Helvetica Neue;
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 17px;
   letter-spacing: 0.04em;
   text-transform: capitalize;

   color: #969CA4;
   
}
.merchant_action_TabsBtn{
   padding: 15px 20px 0 20px;
    height: 100%;
    display:inline-block;
    -webkit-appearance:button;
    position:relative;
    cursor: pointer;
  
}
.AllorderPage_activeTab {
   background-color: inherit;
   border-bottom: solid 2px  #282828;
   color: #282828;
 }

.merchant_action_mainContainer_body_allContent{
    width: 96%;
    min-height: 190px;
    padding-top: 25px;
    background-color: rgb(255, 255, 255);
}

/* ---------------------- */
.merchant_action_content_shop_setting_top{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.merchant_action_content_shop_setting_top_head h3{
    margin-bottom: 1px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #282828;
    margin-top: -3px;
}
.merchant_action_content_shop_setting_top_head p{
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #969CA4;
    margin-bottom: 3px;

}

.merchant_action_content_shop_setting_body_tabsBtns{
    display: flex;
    gap: 10px;
    padding: 10px 0;
}

.merchant_action_content_shop_setting_bodyTabsBtn{
    padding: 10px 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #969CA4;
    cursor: pointer;

}

.merchant_action_content_shop_setting_body_activeTab{
    background: #282828;
    border-radius: 6px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}


.merchant_action_footer{
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 70px;
    background-color:rgb(255, 255, 255);
}
.merchant_action_footer_backbtn{
   position: absolute;
   right: 30px;
   outline: none;
   border: none;
   width: 95px;
   border-radius: 4px;
   height: 38px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    color: #282828;

}
.merchant_action_content_basic_setting,
.merchant_action_content_chat_setting,
.merchant_action_content_notification_setting{
    margin: 20px 0 15px 0;
}
.merchant_action_mainContainer{
    width: 100%;
    justify-content: space-between;
    min-height: 591px;
    position: relative;
    display: flex;

    flex-direction:column ;
    align-items: space-between;
}

.merchant_action_content_settingbox{
    display: flex;
    justify-content: space-between;
    
    
    
}
.merchant_action_content_setting_option{
    width: 70%;
    height: 100%;
    
}
.merchant_action_content_setting_option_heading{
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.02em;

    color: #282828;
}

.merchant_action_content_setting_option_detail{
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    margin-top: -2px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #969CA4;
}
.merchant_action_content_settingbox hr {
    border-top: 1px solid rgba(0,0,0,.1);
}

.merchant_action_content_settting_main_container{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    
}
.vacation-mode_btn_click_open{
    margin-top: 10px;
    width: 100%;
    min-height: 200px;
    background: #F5F8FA;
    text-align: center;
    border-radius: 6px;
   
    display: flex;
    flex-direction: column;
    align-items: center;
}
.toggleBtn_settingBox{
     width: 96%;
     min-height: 200px;
    text-align: left;
    margin-top: 15px;
}
.toggleBtn_settingBox_option{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.toggleBtn_settingBox_option label{
    /* font-family: Helvetica Neue; */
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
/* identical to box height */

letter-spacing: 0.02em;

color: #969CA4;

}
.basic_setting_reply_content input{
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px  !important;
    line-height: 17px;
    color: #282828;
    background: #F0F0F0;

    mix-blend-mode: normal;
    opacity: 0.9;
}
.basic_setting_reply_content{
    display: flex;
    width: 68%;
    align-items: center;
    position: relative;
    background-color: rgb(255, 255, 255);


}
.basic_setting_reply_content button{
    height: 30px;
    width: 30px;
    background-color: initial;
    border: none;
    margin-left: -45px;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    z-index: 1;
    mix-blend-mode: normal;
    
}
.basic_setting_reply_content button{
    color: #14B4E6;
}
.basic_setting_reply_content p{
            position: absolute;
            right: 5px;
            top: 10px;
            font-size: 15px;
            color: rgb(187, 186, 186);
}
.basic_setting_reply_content textarea{
    width: 93%;
    height: 120px;
    border: none;
    outline: none;
    font-size: 13px;
    padding: 10px 0 0 8px;
}




/* ------------------------- */
.working_hour_setting{
    width: 280px;
    height: 400px;
    margin-top: 15px;
    margin-bottom: 15px;

}
.working_hour_setting_top{
    height: 24px;
    
}

.working_hour_setting_table{
    width: 100%;
    border-collapse: separate;
  border-spacing: 0 15px;
  /* text-align: center; */
}
.working_hour_start,
.working_hour_end{
    border: 1px solid rgb(187, 184, 184);
    border-radius: 3px;
    text-align: center;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #969CA4;
}
.to_text{
    text-align: center;
}
.daysName,
.to_text{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #969CA4;
}
.working_hour_setting .text-primary:hover{
    cursor: pointer;
}
.circle{
    width: 25px;
    height: 25px;
    border-radius: 20px;
    background: #FFFFFF;
}





.product_body{
    background: #FFFFFF;
border: 1px solid #E2E2E8;
box-sizing: border-box;
border-radius: 10px;
}
.rating-checked {
    color: orange;
  }
  
  .lgb-background {
    background: #f5f8fa;
  }
  .paymente-completed{
      color: #56CC8B;
  }
  .product-category-border{
      border-radius: 4px;
  }







  /* ----Broadcast Management */

.broadcast_management_main_container{
    width: 94%;
    min-height: 500px;
    border-radius: 10px;
    margin: 10px auto ;
    display: flex ;
    flex-direction: column;
    gap: 10px; 
}

.broadcast_management_performance_listing{
    width: 100%;
    min-height: 300px;
    border-radius: 10px ;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.broadcast_management_performance_listing{
padding-bottom: 20px;
}



/* ------------------------------- */
 .broadcast_management_create_blogs_section{
    width: 100%;
    min-height: 150px;
    padding: 10px;
    background-color: rgb(221, 59, 59);
    border: 1px solid black;
    }
.broadcast_management_create_blogs_section div{
    border-right: 1px solid #E2E2E8;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    margin: 5px;
    width: 250px;
}
.broadcast_management_create_blog_btn{
    letter-spacing: 0.5px;
    padding: 8px 15px;
    background-color: #282828;
    color: white;
    border: none;
    border-radius: 6px;
}
.broadcast_management_blog_para1{
    font-weight: 600;
    margin-bottom: 3px;
}
.broadcast_management_blog_para2{
    color: #969CA4;
    margin-bottom: 0px;
}
.broadcast_management_created_table{
    white-space: nowrap;
    overflow: auto;
}
.broadcast_management_created_table table td{
    padding: 15px;
    border: none;
    border-top: 1px solid #E2E2E8;
}
.broadcast_management_created_table table thead tr td{
    color: #969CA4;
}




/* ===== */


























    /* .broadcast_management_create_blogs_section{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    }
.broadcast_management_create_blogs_section div{
    border-right: 1px solid #E2E2E8;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    margin: 5px;
    width: 250px;
}
.broadcast_management_create_blog_btn{
    letter-spacing: 0.5px;
    padding: 8px 15px;
    background-color: #282828;
    color: white;
    border: none;
    border-radius: 6px;
}
.broadcast_management_blog_para1{
    font-weight: 600;
    margin-bottom: 3px;
}
.broadcast_management_blog_para2{
    color: #969CA4;
    margin-bottom: 0px;
}
.broadcast_management_created_table{
    white-space: nowrap;
    overflow: auto;
}
.broadcast_management_created_table table td{
    padding: 15px;
    border: none;
    border-top: 1px solid #E2E2E8;
}
.broadcast_management_created_table table thead tr td{
    color: #969CA4;
}
 */







/* ------------------------------ */


    .broadcast_management_page_mainContainer_head{
        width: 100%;
        height: 13%;
        min-height: 10px;
        border-radius: 10px 10px 0 0 ;
        background-color: rgb(255, 255, 255);
    }
    .broadcast_management_page_mainContainer_head>p{
        margin-left: 24px;
        padding-top: 24px;
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #282828;

    }
 .broadcast_management_page_mainContainer_body{
     width: 95%;
     /* min-height: 300px;  */
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     border-radius: 10px;     
     background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box; 

 }
 .broadcast_management_page_mainContainer_body_allTabs{
     display: flex;
     width: 100%;
     height: 50px;
     align-items: center;
     padding-left: 17px;
     background: #F5F8FA;
    border-bottom: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #969CA4;
    
 }
 .broadcast_management_page_TabsBtn{
    padding: 15px 20px 0 20px;
     height: 100%;
     display:inline-block;
     -webkit-appearance:button;
     position:relative;
     cursor: pointer;
   
 }
 .AllorderPage_activeTab {
    background-color: inherit;
    border-bottom: solid 2px  #282828;
    color: #282828;
  }

  


 .broadcast_management_page_mainContainer_body_allContent{
     width: 96%;
     /* min-height: 290px; */
     background-color: rgb(255, 255, 255);
 }
 .broadcast_management_page_mainContainer_body_bottom{
     display: flex;
     align-items: center;
     justify-content: space-between;
     width: 96%;
     min-height: 68px;
     border-radius: 0 0 10px 10px;
     background-color: rgb(255, 255, 255);
     
 }
 .broadcast_management_page_mainContainer_body_bottom_selectQty{
     width: 75px;
     height: 35px;
     background: #F5F8FA;
     border-radius: 6px;
     
 }
 .broadcast_management_page_mainContainer_body_bottom_selectQty select{
     border: none;
     outline: 0;
     width: 65px;
     height: 35px;
     padding: 0 12px;
     background: #F5F8FA;
     border-radius: 6px;
     font-family: Helvetica Neue;
     font-style: normal;
     font-weight: 500;
     font-size: 14px;
     line-height: 17px;
     letter-spacing: 0.5px;     
     color: #282828;
 }

 .broadcast_management_page_mainContainer_body_bottom_pagenation a {
    color: black;
    float: left;
    padding: 5px 12px;
    text-decoration: none;
    transition: background-color .3s;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #282828;
  }
.broadcast_management_page_mainContainer_body_bottom_pagenation .broadcast_management_page_mainContainer_body_bottom_pagenation>a.active {
  background-color: dodgerblue;
  color: white;
}
.broadcast_management_page_mainContainer_body_bottom_pagenation a:hover:not(.active) {
  background: #282828;
  border-radius: 4px;
  color: white;}






/* ------------------------- */



/* --------broadcast message section------- */
.broadcast_send_message{
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;


}
.broadcast_send_message_main_container{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgb(241, 241, 241);
    width: 96%;
}
.broadcast_send_message footer{
   height: 70px;
   width: 100%;
   display: flex; justify-content: flex-end;
   align-items: center;
   background-color: rgb(255, 255, 255);
   box-shadow: 1px 1px 3px 2px rgb(220, 217, 217,23);
   /* position: absolute; */
   bottom: 0;
}
.btn_group_broadcast{
    display: flex;
    gap: 10px;
    margin-right: 20px;
}
.broadcastmsgFooter{
    border-radius: 5px;
    min-width: 100px;
    padding: 0 20px;
    height: 40px;
    box-shadow: 1px 1px 1px 1px rgb(220, 217, 217,23);
    
}
.broadcast_send_message_main_container table{
    margin-left: -18%;
}
.broadcast_send_message_main_container table td{
   padding-bottom: 20px;
}


.lableText{
   text-align: end;
   padding-right: 30px;
}

.spacial{
   vertical-align: top;
 text-align:right;
}
.spacial_input input{
    width: 20px;
    
}
.input_lenght input{
    width: 100%;
    height: 45px;
    border: none;
    outline: none;
}

.input_lenght textarea{
    width: 100%;
    height: 160px;
    border: none;
    outline: none;
}
.input_lenght span{
    margin: 8px 8px 0 0;
}
.input_lenght{
    display: flex;
    border: 1px solid rgb(227, 219, 219);
    width: 88%;
    border-radius: 5px;
    padding-left: 3px;
    color: rgb(197, 193, 193);
    
}

.broadcastNameSetting input,
#selectTime{
  height: 35px;
  color: 1px solid rgb(199, 194, 194);
  
}
.broadcastNameSetting{
    display: flex;
    flex-direction: column;
    width: 25%;
    gap: 15px;
}



.broadcast_send_message_main_container h6{
   
    padding-left: 10px;
    font-size: 18px;
    font-weight: 500px;
}

.broadcast_management_created_table table {
    width: 100%;    
}

.broadcast_management_created_table table td {
    border: none;
    border-bottom: 1px solid #eeeef4;
    padding: 20px 8px;
    font-size: 15px;
}
.broadcast_management_table_body_contant_viewIcon{
    width: 30px;
    height: 30px;
    background-color: rgb(220, 245, 241);
    padding: 5px;
    border-radius: 3px;

}
.broadcast_management_table_body_contant_viewIcon img{
    width: 20px;
    height: 20px;
}


/* marchant help center */

.merchant_ticket_listing{
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 2px 3px rgb(241, 241, 241);
}
.merchant_ticket_listing_box{
    padding: 5px;
    margin: 25px;
    border: 1px solid #E2E2E8;
    border-radius: 5px;
}
.merchant_ticket_btn__section{
    position: relative;
}
.add_merchant_ticket_export_btn{
    position: absolute;
    right: 150px;
    top: 10px;
    font-size: 14px;
    letter-spacing: 0.5px;
    padding: 5px 18px;
    background-color: #282828;
    color: white;
    border: none;
    border-radius: 4px;
    z-index: 999;
}
.add_merchant_ticket_btn{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    letter-spacing: 0.5px;
    padding: 5px 10px;
    background-color: #282828;
    color: white;
    border: none;
    border-radius: 4px;
}

.merchant_ticket_detail__table{
    position: relative;
}
.merchant_ticket_listing_detail__table{
    overflow: auto;
    margin-top: 30px;
    white-space: nowrap;
}
.merchant_ticket_action_table{
    position: absolute;
    z-index: 99;
    right: 0px;
    top: 0px;
    background-color: white;
    border: none;
    border-left: 3px solid #E2E2E8;
}
.merchant_ticket_listing_detail__table table thead tr th, .ticket_listing_action_header{
    color: #969CA4;
    border-top: none;
}
.merchant_ticket_listing_detail__table tbody tr td{
    padding: 16.2px;
    border: none;
    border-top: 1px solid #E2E2E8;
}
.merchant_ticket_action_table .table thead tr th{
    border-top: none;
}
.merchant_ticket_action_table .table tbody tr td{
    border: none;
    padding: 14.4px;
    border-top: 1px solid #E2E2E8;
}
.merchant_ticket_listing_actiontable__icon1{
    background-color: #F5F8FA;
    padding: 4px;
    border-radius: 6px;
    width: 25px;
}
.merchant_ticket_actiontable__icon2{
    margin: 0px 4px;
    background-color: #F5F8FA;
    padding: 5px 6px;
    border-radius: 6px;
    width: 26px;
}
.merchant_ticket_hidepage__list{
    border: none;
    border-radius: 5px;
    background-color: #F5F8FA;
    padding: 2px 5px;
    width: 70px;
}
.merchant_ticket_page__slide{
    float: right;
    margin-top: -25px;
    font-size: 20px;
    margin-right: 5px;
}
.merchant_ticket_slide__number{
    padding: 2px 8px;
    font-size: 17px;
    margin: 2px 10px;
    border-radius: 4px;
    color: white;
    background-color: #282828;
}

.uu{
    display: flex;
    width: 100px;
}








