.marketing_Promo_Official_Card{
    background-color:#F5F8FA !important;
    cursor: pointer;
    height: 100%;
    min-height: 22vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.marketing_Promo_Official_Card p {

    font-size: 15px;
    margin-bottom: 2px !important;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
    border-width: 1px;
    border-radius: 2px;
    padding: 2px;
}

.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) {
    border-width: 1px;
}