@import './static/scss/common.scss';
@import './static/scss/variables.scss';
@import './static/scss/classess.scss';
@import './static/css/logInSignUp.css';
@import './static/css//home.css';





.App {
  // text-align: center;
  background-color: #6c757d14;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.App{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  .main-header{
    position: fixed;
    top: 0;
    width: 100%;
    height: 76px;
    z-index: 1000;
    right: 0;
    display: flex;
  }
  .home-body{
    height: calc(100vh - 76px);
    position: absolute;
    width: 100%;
    // padding-bottom: 10px;
    bottom: 0;
    overflow: hidden;
  }  
}

.home{
  /* padding-top: 20px; */
  height: calc(100vh - 100px);
  position: fixed;
  bottom: 0;
  width: 100%;
}
label.imp::after{
  content: "*";
  color: tomato;
}
.h-fit-content{
  height: fit-content;
}
.w-fit-content{
  width: fit-content;
}

.MuiAppBar-root{
  height: 76px;
  justify-content: center;
}


.home_body{
 overflow-y: auto;
}
.container_body{
  min-width: 820px;
}
// .home_sidebar{
//   // overflow-y: auto;
// }
.home-body.desktop{
  .home_sidebar.max, .sidebar_nav.max{
    width: 300px;
  }
  .home_sidebar.min, .sidebar_nav.min{
    width: 65px;
  }
  .home_body.max{
    width: calc( 100vw - 300px );
  }
  .home_body.min{
    width: calc( 100vw - 065px );
  }
}


.main-header.mobile{
  .MuiAppBar-root.header_body{
    width: 100% !important
  }
}
.main-header.desktop{
  .MuiAppBar-root.header_body.min{
    width: calc( 100% - 65px) !important;
    right: 0;
    position: absolute;
  }
  .MuiAppBar-root.header_body.max{
    width: calc( 100% - 300px) !important;
    right: 0;
    position: absolute;
  }
  .MuiAppBar-root.header_sidebar.min{
    width: 65px !important;
    left: 0;
    position: absolute;
  }
  .MuiAppBar-root.header_sidebar.max{
    width: 300px !important;
    left: 0;
    position: absolute;
  }
}

.card{
  border: 0;
}

// sidebar 
.mv_side_navbar{
  max-width: 360px !important;
}

.mu-dialog{
  .MuiDialog-container > div{
    width: 100% !important;
  }
}

.mu-dialog-content{
  width: 100%;
  padding: 0 !important;
}


.MuiTableContainer-root{
  max-height: calc(100vh - 250px) !important;
}

.MuiTableRow-head {
  th{
    z-index: 1 !important;
  }
}

button{
  cursor: pointer;
}
.min-width-200{
  min-width: 200px;
}

.table_action_item{
  // color: #969CA4;
  white-space: nowrap;
  position: sticky;
  right: 0;
  background: #fff;
}
.table_action_head{
  color: #969CA4;
  white-space: nowrap;
  position: sticky;
  right: 0;
  background: #fff;
}
.th_cell{
  color: #969CA4 !important;
  white-space: nowrap;
}
.td_cell{
  white-space: nowrap;
}
.pointer{
  cursor: pointer;
}

.vacation_mode_content{
  background-color: #F5F8FA;
  border-radius: 4px;
}
.notification_setting_content{
  background-color: #F5F8FA;
  border-radius: 4px;
}
.vacation_mode_content .auto_reply .edit_txt{
  position: absolute;
  right: 12px;
  top: 8px;
  cursor: pointer;
}

.working_hour{
  width: 300px;
}
.working_hour .schedule_list ul li input{
  width: 70px;
  height: 31px;
}

div[visible=false]{
  display: none !important;
}
button[visible=false]{
  display: none !important;
}



.form-group .react-select-label {
  /* position: absolute;
  top: -14px;
  z-index: 10000;
  left: 19px;
  background: white;
  padding: 2px 6px; */

  /* z-index: 11; */
  font-size: 14px;
  color: #90a1b7;
  font-weight: 400;
  position: absolute;
  left: 20px;
  top: 9px;
  margin: 0;
  transition: 0.2s all ease;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  -ms-transition: 0.2s all ease;
  -o-transition: 0.2s all ease;
  padding: 0 7px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 95%;
}

.react-select-parent.focused-react-select + .react-select-label {
  position: absolute;
  top: -14px;
  z-index: 11;
  left: 19px;
  background: white;
  padding: 2px 6px;
}

.react-select  .react_select_filter__menu{
  z-index: 11;
  /* background-color: green; */
}

.pointer{
  cursor: pointer;
}
.upload_image_label{
  width: 80px;
  height: 80px;
  object-fit: cover;
}


.form-group .input-icon.icon-left {
  position: absolute;
  left: 10px;
  top: 19px;
  line-height: 0;
  font-size: 14px;
  color: #a7b3c4;
  cursor: pointer;
  z-index: 9;
}
.form-group .input-icon.icon-right {
  position: absolute;
  right: 20px;
  top: 19px;
  line-height: 0;
  font-size: 14px;
  color: #a7b3c4;
  cursor: pointer;
  z-index: 9;
}
.form-field.input_icon_right ~ .form-label {
  padding-right: 50px !important;
}
.form-field.input_icon_left ~ .form-label {
  left: 35px;
}
.form-group .search {
  position: absolute;
  right: 10px;
  top: 19px;
  line-height: 0;
  font-size: 14px;
  color: #a7b3c4;
  cursor: pointer;
  z-index: 3;
  border: 0;
  background: transparent;
}
.show {
  display: block;
}
input.input_icon_right {
  padding-right: 50px;
}
input.input_icon_left {
  padding-left: 35px;
}

.counter_btn{
  background-color: #E2E2E8;
}

.dialog_container{
  padding: 20px;
}
.dialog_container .close_icon{
  display: flex;
    justify-content: flex-end;
    margin-top: -10px;
    margin-right: -12px;
    cursor: pointer;
}
.dialog_container .close_icon svg{
  width: 0.7em;
  height: 0.7em;
}

.word_wrap{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.category-tree ul {
  list-style: none;
}
.category-tree ul li {
  background-color: whitesmoke;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}
.category_list_item.active{
  background-color: rgba(0, 0, 0, 0.04);
}
div[active=true] {
  color: tomato;
}
.cursor-none{
  cursor: not-allowed !important;
}
.underline{
  text-decoration: underline;
}