

.OrdersPage_mainContainer{
    width:1000px;
    min-height: 400px;
    margin-top: 20px;
    margin-left: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
    padding-bottom: 15px; 
}
    .OrdersPage_mainContainer_head{
        width: 100%;
        height: 13%;
        min-height: 10px;
        border-radius: 10px 10px 0 0 ;
        background-color: rgb(255, 255, 255);
    }
    .OrdersPage_mainContainer_head>p{
        margin-left: 24px;
        padding-top: 24px;
        /* font-family: Helvetica Neue; */
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #282828;

    }
 .OrdersPage_mainContainer_body{
     width: 95%;
     min-height: 300px; 
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     border-radius: 10px;     
     background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box; 
    

 }
 .OrdersPage_mainContainer_body_allTabs{
     display: flex;
     width: 100%;
     height: 50px;
     align-items: center;
     padding-left: 17px;
     background: #F5F8FA;
    border-bottom: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #969CA4;
    
 }
 .OrdersPage_TabsBtn{
    padding: 15px 20px 0 20px;
     height: 100%;
     display:inline-block;
     -webkit-appearance:button;
     position:relative;
     cursor: pointer;
   
 }
 .AllorderPage_activeTab {
    background-color: inherit;
    border-bottom: solid 2px  #282828;
    color: #282828;
  }

 .OrdersPage_mainContainer_body_allContent{
     width: 96%;
     min-height: 290px;
     padding-top: 25px;
     background-color: rgb(255, 255, 255);
 }
 .OrdersPage_mainContainer_body_bottom{
     display: flex;
     align-items: center;
     justify-content: space-between;
     width: 96%;
     min-height: 68px;
     border-radius: 0 0 10px 10px;
     background-color: rgb(255, 255, 255);
     
 }
 .OrdersPage_mainContainer_body_bottom_selectQty{
     width: 75px;
     height: 35px;
     background: #F5F8FA;
     border-radius: 6px;
     
 }
 .OrdersPage_mainContainer_body_bottom_selectQty select{
     border: none;
     outline: 0;
     width: 65px;
     height: 35px;
     padding: 0 15px;
     background: #F5F8FA;
     border-radius: 6px;
     /* font-family: Helvetica Neue; */
     font-style: normal;
     font-weight: 500;
     font-size: 14px;
     line-height: 17px;
     letter-spacing: 0.5px;     
     color: #282828;
 }

 .OrdersPage_mainContainer_body_bottom_pagenation a {
    color: black;
    float: left;
    padding: 5px 12px;
    text-decoration: none;
    transition: background-color .3s;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #282828;
  }
.OrdersPage_mainContainer_body_bottom_pagenation .OrdersPage_mainContainer_body_bottom_pagenation>a.active {
  background-color: dodgerblue;
  color: white;
}
.OrdersPage_mainContainer_body_bottom_pagenation a:hover:not(.active) {
  background: #282828;
  border-radius: 4px;
  color: white;}
.order_page_search_icon{ 
  margin-left: 7px;
  border-radius: 6px 0 0 6px;
  background: #F5F8FA;
  padding: 6px 2px;
}

.order_page_chat_icon{
    height: 15px;
    width: 15px;
}
.order_page_product_image{
    border-radius: 3px;
    height: 50px;
    width: 55px;
}
.order_page_view_icon{
    height: 13px;
    width: 13px;    
}
.order_page_edit_icon{
    height: 15px;
    width: 15px;
}
.order_page_delete_icon{
    height: 13px;
    width: 13px;
}














/* css start for all orders */



.OrdersPage_allContent_top{
    width: 100%;
    height: 10%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* gap: 10px; */
}
.OrdersPage_allContent_top_totalOrder{
   font-size: 14px;
   font-weight: 600;
}
#OrdersPage_allContent_top_searchBtn{
    display: flex;
    width: 165px;
    height: 30px;
    justify-content: space-between;
}



#OrdersPage_allContent_top_searchBtn input{
    border: none;
    outline: 0;
    width: 140px;
   
    border-radius: 0 6px 6px 0;
    background-color: rgb(185, 176, 176);
    background: #F5F8FA;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
}


.OrdersPage_allContent_body_headbar{
    display: flex;
    border-bottom: 1px solid #E2E2E8;
    /* font-family: Helvetica Neue; */
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #969CA4;
}
.OrdersPage_Product,.OrdersPage_other{
padding: 18px 0;
}
.OrdersPage_Product,.OrdersPage_ProductBox_Product{
   width: 50%;
}
.OrdersPage_other,.OrdersPage_ProductBox_otherDetails{
    width: 15%;
}
.OrdersPage_itemBox_buyerBox{
    display: flex;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 1%;
    letter-spacing: 0.5px;
    color: #282828;
    justify-content: space-between;
    padding: 4px 0;
    background: #F5F8FA;
    border-radius: 4px;

}
.OrdersPage_buyerBox_buyerDetail{
    display: flex;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    gap: 7%;
    width: 40%;
    letter-spacing: 0.5px;
    color: #282828;
}

.OrdersPage_allContent_body{
    overflow-x: scroll;
}

.OrdersPage_buyerBox_buyerDetail_Name{
    display: flex;
    width: 120px;
    height: 20px;
    gap: 8px;
    margin-left: 12px;
    justify-content: center;
    align-items: center;
}
.OrdersPage_buyerBox_buyerDetail_Name p,.OrdersPage_buyerBox_buyerDetail_brand p{
    height: 6px;
    padding-top: 3px;
}

.OrdersPage_buyerBox_buyerDetail_brand{
    display: flex;
    width: 150px;
    height: 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.OrdersPage_buyerBox_orderId{
    padding-right: 20px;
}

.OrdersPage_itemBox_ProductBox{
    width: 100%;
    min-height:40px ;
    display: flex;
    padding: 13px 0 10px 0;
    border-bottom: 1px solid #E2E2E8;  
}
.OrdersPage_ProductBox_Product{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 6px;
}
.OrdersPage_ProductBox_ProductImage{
    border-radius: 3px;
}
.OrdersPage_ProductBox_otherDetails_orderStatus,
.OrdersPage_other_orderStatus{
    width: 19.2% !important;
}
 .OrdersPage_ProductBox_Product>div{
    display: flex;
    flex-direction: column;
    min-height: 3px;
    max-width: 60%;
} 


.OrdersPage_ProductBox_ProductName{
    /* font-family: Helvetica Neue; */
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 17px;
color: #282828;
}
.OrdersPage_ProductBox_Product_varient{
    /* font-family: Helvetica Neue; */
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.5px;
white-space: initial;

color: #969CA4;
}

.OrdersPage_ProductBox_Options{
    display: flex;
    justify-content: space-between;
    
    
}
.OrdersPage_ProductBox_Options button{
    outline: none;
    border: none;
    padding:8px 10px;
    background: #CBCED8;
    border-radius: 3px;
}
.allOrderPage_moreItemBtn{
    display: flex;
    margin-top: 4px;
}
.allOrderPage_moreItemBtn p{
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-top: 6px;
    letter-spacing: 0.5px;
    color: #969CA4;
}
.allOrderPage_moreItemBtn button{
    border: none;
    outline: none;
    width: 25px;
    height: 20px;
    background-color: inherit;

}





/* -------css start for order cancellation */







  

.cancel_order_page_allContent_top{
    width: 100%;
    height: 10%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* gap: 10px; */
}
.cancel_order_page_allContent_top_totalOrder{
   font-size: 14px;
   font-weight: 600;
}
#cancel_order_page_allContent_top_searchBtn{
    display: flex;
    width: 165px;
    height: 30px;
    justify-content: space-between;
}


#cancel_order_page_allContent_top_searchBtn input{  
    border: none;
    outline: 0;
    width: 140px;
   
    border-radius: 0 6px 6px 0;
    background-color: rgb(185, 176, 176);
    background: #F5F8FA;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
}


.cancel_order_page_allContent_body_headbar{
    display: flex;
    border-bottom: 1px solid #E2E2E8;
    /* font-family: Helvetica Neue; */
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #969CA4;
}
.cancel_order_page_Product,.cancel_order_page_other{
padding: 18px 0;
}
.cancel_order_page_Product,.cancel_order_page_ProductBox_Product{
   width: 45%;
}
.cancel_order_page_other,.cancel_order_page_ProductBox_otherDetails{
    width: 15%;
}
.cancel_order_page_itemBox_buyerBox{
    display: flex;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 1%;
    letter-spacing: 0.5px;
    color: #282828;
    justify-content: space-between;
    padding: 4px 0;
    background: #F5F8FA;
    border-radius: 4px;

}
textarea{
    resize: none;
    outline: none;
}
.cancel_order_page_buyerBox_buyerDetail{
    display: flex;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    gap: 7%;
    width: 40%;
    letter-spacing: 0.5px;
    color: #282828;
}
.cancel_order_page_buyerBox_buyerDetail_Name{
    display: flex;
    width: 120px;
    height: 20px;
    gap: 8px;
    margin-left: 11px;
    justify-content: center;
    align-items: center;
}
.cancel_order_page_buyerBox_buyerDetail_Name p,.cancel_order_page_buyerBox_buyerDetail_brand p{
    height: 6px;
    padding-top: 3px;
}
.cancel_order_page_buyerBox_buyerDetail_brand{
    display: flex;
    width: 150px;
    height: 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.cancel_order_page_buyerBox_orderId{
    padding-right: 20px;
}
.cancel_order_page_itemBox_ProductBox{
    width: 100%;
    min-height:40px ;
    display: flex;
    padding: 13px 0 10px 0;
    border-bottom: 1px solid #E2E2E8;  
}
.cancel_order_page_ProductBox_Product{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 6px;
}
.cancel_order_page_ProductBox_otherDetails_orderStatus,
.cancel_order_page_other_order_cancel_reason{
    width: 23% !important;
}

 .cancel_order_page_ProductBox_Product>div{
    display: flex;
    
    flex-direction: column;
    min-height: 3px;
    max-width: 60%;
}
.cancel_order_page_ProductBox_ProductName{
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #282828;
}
.cancel_order_page_ProductBox_Product_varient{
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: #969CA4;
}

.cancel_order_page_ProductBox_Options{
    display: flex;
    justify-content: space-between;  
}
.cancel_order_page_ProductBox_Options div{
    height: 35px;
    width: 35px;
    background: #F5F8FA;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
}



/* ----------css for return and refund---------- */

.return_page_head_btns{
    display: flex;
    gap: 5px;
    padding: 0px 0 15px 0;
}
.return_page_head_btns button{
    border: none;
    outline: none;
    padding: 7px 20px;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #969CA4;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 10px;
}

.return_page_allContent_top{
    width: 100%;
    height: 10%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* gap: 10px; */
}
.return_page_allContent_top_totalOrder{
   font-size: 14px;
   font-weight: 600;
}
#return_page_allContent_top_searchBtn{
    display: flex;
    width: 165px;
    height: 30px;
    justify-content: space-between;
}



#return_page_allContent_top_searchBtn input{
    border: none;
    outline: 0;
    width: 140px;
   
    border-radius: 0 6px 6px 0;
    background-color: rgb(185, 176, 176);
    background: #F5F8FA;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
}


.return_page_allContent_body_headbar{
    display: flex;
    border-bottom: 1px solid #E2E2E8;
    /* font-family: Helvetica Neue; */
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #969CA4;
}
.return_page_Product,.return_page_other{
padding: 18px 0;
}
.return_page_Product,.return_page_ProductBox_Product{
   width: 45%;
}
.return_page_other,.return_page_ProductBox_otherDetails{
    width: 15%; 
}
.return_page_itemBox_buyerBox{
    display: flex;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 1%;
    letter-spacing: 0.5px;
    color: #282828;
    justify-content: space-between;
    padding: 4px 0;
    background: #F5F8FA;
    border-radius: 4px;

}
.return_page_buyerBox_buyerDetail{
    display: flex;
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    gap: 7%;
    width: 40%;
    letter-spacing: 0.5px;
    color: #282828;
}
.return_page_buyerBox_buyerDetail_Name{
    display: flex;
    width: 120px;
    height: 20px;
    gap: 8px;
    margin-left: 12px;
    justify-content: center;
    align-items: center;
}
.return_page_buyerBox_buyerDetail_Name p,.return_page_buyerBox_buyerDetail_brand p{
    height: 6px;
    padding-top: 3px;
}

.return_page_buyerBox_buyerDetail_brand{
    display: flex;
    width: 150px;
    height: 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.return_page_buyerBox_orderId{
    padding-right: 20px;
}
.return_page_itemBox_ProductBox{
    width: 100%;
    min-height:40px ;
    display: flex;
    padding: 13px 0 10px 0;
    border-bottom: 1px solid #E2E2E8;  
}
.return_page_ProductBox_Product{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 6px;
}
.return_page_ProductBox_ProductImage{
    border-radius: 3px;
}
.return_page_ProductBox_otherDetails_orderStatus,
.return_page_other_orderStatus{
    width: 21% !important;
}
 .return_page_ProductBox_Product>div{
    display: flex;
    flex-direction: column;
    min-height: 3px;
    max-width: 60%;
} 
.return_page_ProductBox_ProductName{
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #282828;
}
.return_page_ProductBox_Product_varient{
    /* font-family: Helvetica Neue; */
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;    
    color: #969CA4;
}
.return_page_other_active button{
    outline: none;
    border: none;
    background-color: inherit;
    color: blue;
    font-size: 14px;
    font-weight: 600;
    cursor: default;
}



/* -----refund page csss */

.refund_request_main_section{
    width: 100%;
}
.refund_request_discussion_section1{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    /* margin-top: 25px; */
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgb(241, 241, 241);

}
.refund_request_para1{
    font-weight: 500;
    margin-bottom: 3px;
}
.refund_request_para2{
    color: #969CA4;
    font-size: 14px;
}
.refund_discussiion_btn_section{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
.refund_request_discussion_btn{
    font-size: 15px;
    padding: 6px 15px;
    background-color: #282828;
    color: white;
    border: none;
    border-radius: 4px;
}
.refund_request_discussion_section2{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow:1px 1px 1px 1px rgb(241, 241, 241);

}
.refund_mouse_image{
    width: 15%;
    margin-bottom: 15px;
}
.refund_request_discussion_section1_heading{
    padding-top: 5px;
    margin-top: 5px;
    font-size: 14PX;
}

.home_body.max {
    padding-top: 22px;
    background: #f0f0f0;
}

.backBtnrefundPage{
    border: none;
    outline: none;
    color: black;
    background-color: inherit;
    font-size: 14px;
}

.backBtnrefundPage span{
    font-size: 16px;
}



.refund_request_info_section2{
    overflow-y: scroll;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 20px;
    min-height: 700px;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgb(241, 241, 241);
}
.refund_request_info_time_section{
    background-color: #E2E2E8;
    padding: 8px 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border-radius: 6px;
}
.refund_request_info_time_section{
    background-color: #E2E2E8;
    padding: 8px 15px;
    box-sizing: border-box;
    border-radius: 6px;
}
.refund_request_info_time_section p{
    font-size: 14px;
}
.refund_request_info_para3{
    font-size: 14px;
    color: #969CA4;
}
.refund_massageUU2Agent{
    font-size: 14px;
}
.refund_request_info_para1{
    font-weight: 500;
    margin-bottom: 5px;
}
.refund_request_info_para2{
    margin-bottom: 0px;
}
.refund_info_uu2_img_section{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}
.refund_info_uu2_img_section div{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E2E2E8;
    border-radius: 50%;
    margin-right: 10px;
}
.refund_request_info_msg_section{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}
.refund_info_back_arrow_img{
    width: 7px;
    margin-bottom: 2px;
    margin-right: 3px;
}
.refund_request_info_msg_section input{
    border: 1px solid #E2E2E8;
    border-radius: 4px;
    width: 100%;
    padding: 5px 10px;
    margin: 0px 10px;
    outline: none;
    font-size: 14px;
}
.refund_info_msg_send_btn{
    color: #FFFFFF;
    background-color: #C9C9C9;
    border: none;
    border-radius: 6px;
    padding: 5px 20px;
    text-align: center;
}
.image_gallery_font{
    font-size: 24px;
}





/*   view order css */

.Order-name{
  font-size:90%;
}
.ORDER-INFORMATION{
  display:flex;
  justify-content: space-between;
  flex-direction: row;
}
.product-item{
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 80%;
}
.product-name{
  font-size: 70%;
}
.total-amount{
  font-size: 18px;
  font-weight: 500;
  color:rgb(107, 216, 180)
}


.track-line {
  height: 3px !important;
  color:rgb(107, 216, 180)
}

.dot {
  height: 10px;
  width: 10px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 0px;
  background-color: rgb(91, 186, 153);
  border-radius: 50%;
  display: inline-block
}

.big-dot {
  height: 25px;
  width: 25px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  background-color: rgb(131, 221, 169);
  border-radius: 50%;
  display: inline-block
}

.big-dot i {
  font-size: 12px
}
.dot-space{
  height: 10px;
  width: 10px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 0px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block
}

.deliver-time{
  font-size:60%;
}
.delivered{
  color:rgb(101, 245, 219);
}
.address{
  font-size:90%;
}
.delivere-detail{
  font-size:80%;
}

.vieworder_delivered_containter{
    position: relative;
}
.vieworder_delivered_edit_btn{
    position: absolute;
    outline: none;
    border: none;
    right: 18px;
    top: 0px;
}
.vieworder_logistic_status_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px ;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 35px;
    border-bottom: 1px solid rgb(199, 194, 194);
}
.vieworder_logistic_status_box label{
    padding: 10px 10px 0 10px;
    font-size: 14px;
}
#logisticStatus{
    width: 384px;
    height: 44px;
    padding: 10px;
    font-size: 14px;
    outline: none;
    border-radius: 5px;
    background-color: inherit;
}
.vieworder_logistic_status_box p{ 
    font-size: 11px;
    color: gray;
    margin-left: 123px;
    margin-top: 8px;
    margin-bottom: 0;
}

.vieworder_logistic_status_box button{
    border: none;
    margin-left: 10px;
    height: 44px;
    border-radius: 3px;
    font-size: 14px;
}


/* ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }  */
  
  /* Track */
  /* ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  } */
   
  /* Handle */
  /* ::-webkit-scrollbar-thumb {
    background: #F0F0F0; 
    border-radius: 10px;
  } */
  
  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
    background: #e3e2e2; 
  } */


/* ------------oder listing -------------- */
.tablehead,.tableheadfix{
    display: inline-block;
    width: 8rem;
    padding: 13px 5px;
    /* border-bottom: 1px solid #c5b8b8; */
    color:rgb(181, 172, 172)
  } 
  .tableheadProduct{
    display: inline-block;
    width: 24rem;
    /* border-bottom: 1px solid #c5b8b8; */
    padding: 13px 0px;
    color:rgb(181, 172, 172)
  }
.tableheadfix{
    background: white;
    position: sticky;
    right: 0;
    top:0;
    z-index: 2;
    text-align: center;
  }
.allOrderMain{
    overflow-x: scroll;
    width: auto;
    white-space: nowrap;
    position: relative;
    padding: 10px 0 3rem 0;
}
.fix{
    position: relative;
    right: 0;
    display: flex;
    width: 100%;
    margin: 15px 0;
    justify-content:space-between;
    padding: 3px;
    border-radius:2px ;
    background: #F5F8FA;}
.hhh{
    padding: 0;
    display: inline-block;
    ;

}
.borderLine{
        border-bottom: 1px solid #c5b8b8;

}

.info{
    border-bottom: 1px solid #c5b8b8;
}

/* --------------------------------- */

/*  */




/*   view order css */

.Order-name{
    font-size:90%;
  }
  .ORDER-INFORMATION{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .product-item{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 80%;
  }
  .product-name{
    font-size: 70%;
  }
  .total-amount{
    font-size: 18px;
    font-weight: 500;
    color:rgb(107, 216, 180)
  }
  
  
  .track-line {
    height: 3px !important;
    color:rgb(107, 216, 180)
  }
  
  .dot {
    height: 10px;
    width: 10px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 0px;
    background-color: rgb(91, 186, 153);
    border-radius: 50%;
    display: inline-block
  }
  
  .big-dot {
    height: 25px;
    width: 25px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    background-color: rgb(131, 221, 169);
    border-radius: 50%;
    display: inline-block
  }
  
  .big-dot i {
    font-size: 12px
  }
  .dot-space{
    height: 10px;
    width: 10px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 0px;
    background-color: gray;
    border-radius: 50%;
    display: inline-block
  }
  
  .deliver-time{
    font-size:60%;
  }
  .delivered{
    color:rgb(101, 245, 219);
  }
  .address{
    font-size:90%;
  }
  .delivere-detail{
    font-size:80%;
  }
  
  .vieworder_delivered_containter{
      position: relative;
  }
  .vieworder_delivered_edit_btn{
      position: absolute;
      outline: none;
      border: none;
      right: 18px;
      top: 0px;
  }
  .vieworder_logistic_status_box{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px ;
      font-size: 14px;
      margin-top: 15px;
      margin-bottom: 35px;
      border-bottom: 1px solid rgb(199, 194, 194);
  }
  .vieworder_logistic_status_box label{
      padding: 10px 10px 0 10px;
      font-size: 14px;
  }
  #logisticStatus{
      width: 384px;
      height: 44px;
      padding: 10px;
      font-size: 14px;
      outline: none;
      border-radius: 5px;
      background-color: inherit;
  }
  .vieworder_logistic_status_box p{ 
      font-size: 11px;
      color: gray;
      margin-left: 123px;
      margin-top: 8px;
      margin-bottom: 0;
  }
  
  .vieworder_logistic_status_box button{
      border: none;
      margin-left: 10px;
      height: 44px;
      border-radius: 3px;
      font-size: 14px;
  }
  
  
  /* ::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }  */
    
    /* Track */
    /* ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    } */
     
    /* Handle */
    /* ::-webkit-scrollbar-thumb {
      background: #F0F0F0; 
      border-radius: 10px;
    } */
    
    /* Handle on hover */
    /* ::-webkit-scrollbar-thumb:hover {
      background: #e3e2e2; 
    } */
  
  
  
  /* ---team purchase ---- */
  
  .info{
      border-bottom: 1px solid #c5b8b8;
      width: fit-content;
  }
  
  .teamPurchase_orderId_detail_info{
      width: fit-content;
  }
  .teamPurchase_tablehead,.teamPurchase_tableheadfix{
      display: inline-block;
      min-width: 12rem;
      padding: 13px 5px;
      /* border-bottom: 1px solid #c5b8b8; */
      color:rgb(181, 172, 172)
    } 
  
  .allTeamPurchaseMain{
      overflow-x: auto;
      width: 100%;
      white-space: nowrap;
      position: relative;
      padding: 10px 0 3rem 0;
  }
  .teamPurchase_tablehead_details_head{
      display: flex;
      align-items: flex-start;
  }
  .teamPurchase_borderLine{
      border-bottom: 1px solid #c5b8b8;
  
  }
  .tableheadTeamPurchaseProductName{
      display: inline-block;
      width: 20rem;
      margin-left: 15px;
      /* border-bottom: 1px solid #c5b8b8; */
      padding: 13px 0px;
      color:rgb(181, 172, 172)
  }
  .tableheadTeamPurchaseProduct{
      display: inline-block;
      width: 17rem;
      /* border-bottom: 1px solid #c5b8b8; */
      padding: 13px 0px;
      color:rgb(181, 172, 172)
  }
  .tableheadTeamPurchaseProduct_image{
    width: 12rem;
    /* border-bottom: 1px solid #c5b8b8; */
    padding: 13px 0px;
    color:rgb(181, 172, 172)
  }
  .teamPurchase-orderId-detail-maindiv{
      position: relative;
      right: 0;
      z-index: 1;
      display: flex;
      width: 100%;
      margin: 15px 0;
      justify-content:space-between;
      align-items: center;
      padding: 3px 0px;
      border-radius:2px ;
      background: #F5F8FA;
  }
  .teamPurchase-orderId-detail-section{
      padding: 0;
      display: inline-block;
      position: sticky;
      left: 0;
  }
  .teamPurchase-orderId-detail-indexId{
      position: sticky;
      right: 0;
  }
  .teamPurchase_chat_icon{
      height: 15px;
      width: 15px;
  }  
  .teamPurchase_orderId_Detail_Name{
      display: flex;
      min-width: 100px;
      height: 20px;
      gap: 8px;
      margin-left: 12px;
      justify-content: center;
      align-items: center;
  }
  .teamPurchase_orderId_Detail_brand{
      display: flex;
      /* width: 150px; */
      height: 20px;
      /* justify-content: center; */
      align-items: center;
      gap: 5px;
  }
  .teamPurchase_page_product_image{
      border-radius: 3px;
      height: 50px;
      width: 55px;
  }
  .teamPurchase_Page_ProductBox_ProductName{
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  white-space: break-spaces;
  color: #282828;
  }
  .teamPurchase_Page_ProductBox_Product_varient{
      /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.5px;
  white-space: initial;
  
  color: #969CA4;
  }

  .tableheadfix{
      background: white;
      position: sticky;
      right: 0;
      top:0;
      z-index: 2;
      text-align: center;
      /* border-left: 1px solid #c5b8b8; */
    }
    .tableheadfix_allorder{
        border-left: none !important;
    }
  .teamPurchase_tableheadfix {
      background: white;
      position: sticky;
      right: 0;
      top:0;
      z-index: 2;
      text-align: center;
      border-left: 1px solid #c5b8b8;
    }
    .teamPurchase_orderId_Detail_Name p,.teamPurchase_orderId_Detail_brand p,.teamPurchase-orderId-detail-indexId p{
      height: 8px;
      text-align: center;
  }
  .teamPurchase_page_action_btn{
      display: flex;
      flex-direction: column;
  }
  .teamPurchase_page_action_btn button {
      border: none;
      background: none;
      margin-bottom: 5px;
  }

  .chat_container{
        height: 700px;
        /* overflow-y:auto;  */
}

