$dark: #1d2332f0;
$light: #fff;

$app-color: #282828;
$app-color-shadow: #28282885;
$app_bg_color: #222b37;
$txt-color: #fff;

$drawer_bg_color: #181c24;
$header_color: #0094ea;
$primary: #0094ea;


// colors
$black6: rgba(255, 255, 255, 0.5);