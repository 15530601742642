@import './variables.scss';
.bold{
    font-weight: bold;
}
.Bold{
    font-weight: bold !important;
}
.pointer{
    cursor: pointer;
}
.app-link {
    color: #61dafb;
}
  
.white{
    color: #fff !important;
}

.capitalize{
    text-transform: capitalize !important;
}

.header_color{
	background-color: $header_color !important;
}

#overlay{
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: #00000085;
}

.object-fit-cover{
    object-fit: cover;
}
.object-fit-contain{
    object-fit: contain;
}

.minimize{
    color: #3195ea;
    font-size: small;
    margin: auto;
}
.header_color{
   color: $header_color;
}
.primary{
   color:  $primary
}