

/* ---Shop Rating ---- */
  
  .shop_rating_info{
    border-bottom: 1px solid #c5b8b8;
    width: fit-content;
}


.shopRating_tablehead{
    display: inline-block;
    width: 17rem;
    padding: 13px 5px;
    /* border-bottom: 1px solid #c5b8b8; */
    color:rgb(181, 172, 172)
  } 

.allShopRatingMain{
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    position: relative;
    padding: 10px 0 3rem 0;
}
.shopRating_tablehead_details_head{
    display: flex;
    align-items: flex-start;
}
.shopRating_borderLine{
    border-bottom: 1px solid #c5b8b8;

}
.tableheadShopRatingProduct{
    display: inline-block;
    width: 17rem;
    /* border-bottom: 1px solid #c5b8b8; */
    padding: 13px 0px;
    color:rgb(181, 172, 172)
}
.shopRating-orderId-detail-maindiv{
    position: relative;
    right: 0;
    z-index: 9999;
    display: flex;
    width: 100%;
    margin: 15px 0;
    justify-content:space-between;
    align-items: center;
    padding: 3px 0px;
    border-radius:2px ;
    background: #F5F8FA;
}
.shopRating-orderId-detail-section{
    padding: 0;
    display: inline-block;
    position: sticky;
    left: 0;
}
.shopRating-orderId-detail-indexId{
    position: sticky;
    right: 0;
}

.shopRating_orderId_Detail_Name{
    display: flex;
    width: 120px;
    height: 20px;
    gap: 8px;
    margin-left: 12px;
    justify-content: flex-start;
    align-items: center;
}
.shopRating_orderId_Detail_brand{
    display: flex;
    width: 150px;
    height: 20px;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.shopRating_page_product_image{
    border-radius: 3px;
    height: 50px;
    width: 55px;
}
.shopRating_Page_productBox_mainSection{
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.shopRating_Page_ProductBox_ProductName{
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    white-space: normal;
    max-width: 30rem;
    color: #282828;
}
.shopRating_Page_ProductBox_Product_varient{
    font-family: Helvetica Neue;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.5px;
white-space: initial;

color: #969CA4;
}

  .shopRating_orderId_Detail_Name p,.shopRating_orderId_Detail_brand p,.shopRating-orderId-detail-indexId p{
    height: 8px;
    text-align: center;
}





/* ----- Finance Info------- */

.merchant_income_statement{
    background: #F5F8FA;
}
.marchant_income_statement_section{
    overflow: auto;
    max-height: 14vh;

}
.merchant_balance_reset_btn{
    background-color: #FF731C;
    color: #fff;
    outline: none;
}
