a {
    color: inherit;
    text-decoration: none;
  }
  
  * {
    box-sizing: border-box;
  }
  .Order-name{
    font-size:90%;
  }
  .ORDER-INFORMATION{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .product-item{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 80%;
  }
  .product-name{
    font-size: 70%;
  }
  .total-amount{
    font-size: 18px;
    font-weight: 500;
    color:rgb(107, 216, 180)
  }
  
  
  .track-line {
    height: 3px !important;
    color:rgb(107, 216, 180)
  }
  
  .dot {
    height: 10px;
    width: 10px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 0px;
    background-color: rgb(91, 186, 153);
    border-radius: 50%;
    display: inline-block
  }
  
  .big-dot {
    height: 25px;
    width: 25px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    background-color: rgb(131, 221, 169);
    border-radius: 50%;
    display: inline-block
  }
  
  .big-dot i {
    font-size: 12px
  }
  .dot-space{
    height: 10px;
    width: 10px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 0px;
    background-color: gray;
    border-radius: 50%;
    display: inline-block
  }
  
  .deliver-time{
    font-size:60%;
  }
  .delivered{
    color:rgb(101, 245, 219);
  }
  .address{
    font-size:90%;
  }
  .delivere-detail{
    font-size:80%;
  }