.category-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow-y: scroll;
  height: 350px;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 350px;
}
.d-tree {
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
}
/* d-tree-container::-webkit-scrollbar {
  width: 1em;
  border: 5px solid rgb(245, 133, 133);
}. */

.category-container::-webkit-scrollbar {
  width: 13px;
  border: 5px solid rgb(245, 133, 133);
}

.category-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
}

.category-container::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  background-clip: padding-box;
  border: 0.05em solid #eeeeee;
}

.category-container::-webkit-scrollbar-button:single-button {
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
  height: 10px;
  width: 12px;
}

.category-container::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #555555 transparent;
}

.category-container::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
.category-container::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 7px 7px 0 7px;
  border-color: #555555 transparent transparent transparent;
}

.category-container::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.d-tree-container {
  height: auto;
  list-style: none;
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.d-tree-node {
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  height: auto;
}

/* .d-tree-toggler.active {
   transform: rotate(45deg);
 } */

