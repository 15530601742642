
.product-list-tab{
    border-radius: 7px 7px   0 0;
    background: #f5f8fa;
}
.main-body{
    border: 1px solid gray;
    border-radius: 7px;
    margin: 0 1.5%
}
.search-input {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 4%;
}
.search-input input {
    background: #f5f8fa;
    border-radius: 0 6px 6px 0;
    height: 90%;
    border: none;
    outline: none;
}
.search-icon {  
  border-radius: 6px 0 0 6px;
  background: #f5f8fa;
  border: 1px 0 solid gray;
}

/* .add-product-botton {
  height: 90% !important;
} */
.upArrow {
  height: 0;
}
.active-pro {
    border-bottom: 2px solid black;
    color: black !important;
  }
  .AllOrdersPage_mainContainer_body_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    border-radius: 0 0 7px 7px;
    min-height: 68px;
    margin-left: 1%;
  
    background-color: rgb(255, 255, 255);
  }
  .AllOrdersPage_mainContainer_body_bottom_selectQty {
    width: 75px;
    height: 35px;
    background: #f5f8fa;
    border-radius: 6px;
  }
  .AllOrdersPage_mainContainer_body_bottom_selectQty select {
    border: none;
    outline: 0;
    width: 65px;
    height: 35px;
    padding: 0 15px;
    background: #f5f8fa;
    border-radius: 4px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
  
    color: #282828;
  }
  .AllOrdersPage_mainContainer_body_bottom_pagenation a {
    color: black;
    float: left;
    padding: 5px 12px;
    text-decoration: none;
    transition: background-color 0.3s;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
  
    color: #282828;
  }
  
  .AllOrdersPage_mainContainer_body_bottom_pagenation
    .AllOrdersPage_mainContainer_body_bottom_pagenation
    > a.active {
    background-color: dodgerblue;
    color: white;
  }
  
  .AllOrdersPage_mainContainer_body_bottom_pagenation a:hover:not(.active) {
    background: #282828;
    border-radius: 4px;
    color: white;
  } 



/* /////////////////////////////////////// */

.process-line{
    width:10%;
   margin-top: 1%;
    color:black;
    /* border:0.3px solid gray; */
}
.process-indicate{
    width:5px;
    height:5px;
    background:black;
    border-radius: 50%;
}
.input-group-text {
    border-radius: 5px 0 0 5px;
}
.product-image{
  width:60px;
  height: 75px;
  margin-left: 12px !important;
  border-radius: 8px;
  align-items: center;
  display:flex;
  justify-content:center;
  border: 2px dotted rgb(153, 151, 151)
   
}
.product-image input{
    visibility: hidden;
    width:0%;
    height: 0%;
}
.product-video input{
  visibility: hidden;
  width:0%;
  height: 0%;
}

.product-video{
    width:75px;
    height: 75px;
    margin-left: 5px !important;
    border-radius: 8px;
    align-items: center;
    display:flex;
    justify-content:center;
    border: 2px dotted rgb(153, 151, 151)
}
.video-process{
    font-size: 82%;
}
.input-text-area{
  height: 150px;
  outline:none;
}
.product-type input[type=radio] {
  width: 15px;
  height: 15px;
}

.input-inside-text{
    font-size: 80%;
}
.add-variation-button-click{
    width:83%
}
.variation-list{
    width:15%;
}
.product-Action-position{
  right:0;
  background:white;
  z-index: 3;
  width:13%;
}
.scrollspy-example{
  overflow-x: scroll !important;
  width:87%;
}
.input-box{
  height: 35px;
  outline:none ;
  align-items: center;
  border-radius: 4px;
}
.product-category{
  margin-left: 4rem;

}
.product-edit-line{
  margin-top: 8.4%;
}
.scrollspy-example::-webkit-scrollbar {
  width: 3px;
  height: 8px;
}


.scrollspy-example::-webkit-scrollbar-thumb {
  background: #E2E2E8; 
  border-radius: 90px;
}

.scrollspy-example::-webkit-scrollbar-thumb:hover {
  background: #c9c9ce; 
}

.product-sale-price-input{
  height:110%;
}
.local-bg{
  background:#F5F8FA;
}

.overview-card{
  height:105px !important;
}
.image_options{
  display: none;
}
.product_image_Hover:hover + .image_options{
  display: block;
  /* color: red; */
}