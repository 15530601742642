.product_performance_main_container{
    width: 95%;
    min-height: 500px;
    border-radius: 10px;
    margin: 10px auto ;
    display: flex ;
    flex-direction: column;
    gap: 10px; 
}
.product_performance_overview,
.product_performance_performance_listing{
    width: 100%;
    min-height: 300px;
    border-radius: 10px ;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}
.product_performance_overview{
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding-bottom: 17px;
}
.product_performance_overview_head{
    width: 96%;
    height: 13%;
    min-height: 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px 10px 0 0 ;
    background-color: rgb(255, 255, 255);
 }
 .product_performance_overview_head>p{
   
    padding-top: 24px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #282828;

 }
 .product_performance_overview_head_selectbtns{
     min-width: 50px;
     min-height:10px ;
     display: flex;
     gap: 10px;
 }
 .product_performance_overview_head_product_qty,
 .product_performance_overview_head_today{
    height: 35px;
    margin-top: 15px;
    background: #F5F8FA;
    border-radius: 6px;
    
}
.product_performance_overview_head_product_qty{
    min-width: 110px;
}
.product_performance_overview_head_today{
    min-width: 95px;
}

.product_performance_overview_head_product_qty select,
.product_performance_overview_head_today select{
    border: none;
    outline: 0;
    min-width: 65px;
    height: 35px;
    padding: 0 15px;
   
    background: #F5F8FA;
    border-radius: 6px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;  
    letter-spacing: 0.5px;
    color: #282828;
}
.product_performance_overview_body{
    width: 96%;
    display: grid;
    grid-template-columns: repeat(4,23.8%);
    grid-column-gap: 1.5%;
    grid-row-gap: 14px; 
}
.product_performance_overview_body_itembox{
    min-height: 130px;
    background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px  20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}
.product_performance_overview_body_itemboxtop_title,
.product_performance_overview_body_itemboxtop_detail,
.product_performance_overview_body_itemboxbottom_result{
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.5px;
}
.product_performance_overview_body_itemboxtop_title{
    font-size: 14px;
    line-height: 17px;
    color: #969CA4;
}
.product_performance_overview_body_itemboxtop_detail{
    font-size: 24px;
    line-height: 29px;
    color: #282828;
}
.product_performance_overview_body_itemboxbottom_result{
    font-size: 12px;
    line-height: 14px;
    color: #969CA4;
}
.product_performance_performance_listing{
padding-bottom: 20px;
}






/* ------------------------------ */


    .product_performance_page_mainContainer_head{
        width: 100%;
        height: 13%;
        min-height: 10px;
        border-radius: 10px 10px 0 0 ;
        background-color: rgb(255, 255, 255);
    }
    .product_performance_page_mainContainer_head>p{
        margin-left: 24px;
        padding-top: 24px;
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #282828;

    }
 .product_performance_page_mainContainer_body{
     width: 95%;
     min-height: 300px; 
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     border-radius: 10px;     
     background: #FFFFFF;
    border: 1px solid #E2E2E8;
    box-sizing: border-box; 

 }
 .product_performance_page_mainContainer_body_allTabs{
     display: flex;
     width: 100%;
     height: 50px;
     align-items: center;
     padding-left: 17px;
     background: #F5F8FA;
    border-bottom: 1px solid #E2E2E8;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #969CA4;
    
 }
 .product_performance_page_TabsBtn{
    padding: 15px 20px 0 20px;
     height: 100%;
     display:inline-block;
     -webkit-appearance:button;
     position:relative;
     cursor: pointer;
   
 }
 .AllorderPage_activeTab {
    background-color: inherit;
    border-bottom: solid 2px  #282828;
    color: #282828;
  }

  .product_performance_page_allContent_top{
    width: 100%;
    height: 10%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* gap: 10px; */
}
.product_performance_page_allContent_top_totalOrder{
   font-size: 14px;
   font-weight: 600;
}
#product_performance_page_allContent_top_searchBtn{
    display: flex;
    width: 165px;
    height: 30px;
    justify-content: space-between;
}



#product_performance_page_allContent_top_searchBtn input{
    border: none;
    outline: 0;
    width: 140px;
   
    border-radius: 0 6px 6px 0;
    background-color: rgb(185, 176, 176);
    background: #F5F8FA;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
}


 .product_performance_page_mainContainer_body_allContent{
     width: 96%;
     min-height: 290px;
     padding-top: 25px;
     border-bottom:3px solid grey ;
     background-color: rgb(255, 255, 255);
 }
 .product_performance_page_mainContainer_body_bottom{
     display: flex;
     align-items: center;
     justify-content: space-between;
     width: 96%;
     min-height: 68px;
     border-radius: 0 0 10px 10px;
     background-color: rgb(255, 255, 255);
     
 }
 .product_performance_page_mainContainer_body_bottom_selectQty{
     width: 75px;
     height: 35px;
     background: #F5F8FA;
     border-radius: 6px;
     
 }
 .product_performance_page_mainContainer_body_bottom_selectQty select{
     border: none;
     outline: 0;
     width: 65px;
     height: 35px;
     padding: 0 12px;
     background: #F5F8FA;
     border-radius: 6px;
     font-family: Helvetica Neue;
     font-style: normal;
     font-weight: 500;
     font-size: 14px;
     line-height: 17px;
     letter-spacing: 0.5px;     
     color: #282828;
 }

 .product_performance_page_mainContainer_body_bottom_pagenation a {
    color: black;
    float: left;
    padding: 5px 12px;
    text-decoration: none;
    transition: background-color .3s;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #282828;
  }
.product_performance_page_mainContainer_body_bottom_pagenation .product_performance_page_mainContainer_body_bottom_pagenation>a.active {
  background-color: dodgerblue;
  color: white;
}
.product_performance_page_mainContainer_body_bottom_pagenation a:hover:not(.active) {
  background: #282828;
  border-radius: 4px;
  color: white;}






/* ------------------------- */


.product_performance_page_content table{
    width: 99%;
    margin-top: 20px;
    
    
}
 .custom-select{
     border: none;
     outline: none;
     width: 94px;
     font-family: Helvetica Neue;
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 17px;  
     letter-spacing: 0.5px;
     color: #969CA4;
 }
 .product_performance_page_content th,
 .product_performance_page_content td{
    text-align: end ;
    

}
.product_performance_page_content tr{
    height: 60px;

   padding: 35px 0;
   border-bottom:  1px solid #E2E2E8 ;
   opacity: 0.5;
}
.product_performance_page_content td{
    width: 240px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: #282828;
}
.product_performance_page_content td span{
    color: #969CA4;
}
.product_performance_page_table_right_item{
    width: 320px;
    
    gap:38px;
    display: flex;
    align-items: center;
    text-align: center !important;
    padding: 30px 0;
}
.product_performance_page_table_unique_parchases{
    width: 200px;
}
.custom_select_unique_parchases{
    border: none;
    outline: none;
    width: 150px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;  
    letter-spacing: 0.5px;
    color: #969CA4;
}
.product_performance_page_content th{
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;  
    letter-spacing: 0.5px;
    color: #969CA4;
}
.product_performance_page_table_Quantity{
    width: 100px;
}

.product_performance_page_table_table_last_item{
    padding-right: 5px;
}


.css-1ujnqem-MuiTabs-root {
    background: #F5F8FA;}